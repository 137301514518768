import React from 'react';
import { useHistory } from "react-router-dom";
import Div100vh from 'react-div-100vh'

function Home() {
  const history = useHistory();

  const navToSearch = (e) => {
    e.preventDefault();
    history.push('/search')
  }

  return (
    <Div100vh>
      <div className="home">
        <div className="cherryFlower"></div>
        <div className="cherryFlowerBtm"></div>
        <div className="overlay">
          <div className="welcome">
          <span className="wl-note">Welcome to</span>
            <div className="couples">
              <span className="ch">Ronica</span>
              <span className="ch">&</span>
              <span className="ch">Joseph</span>
            </div>
          <span className="wl-note">Wedding Reception</span>
          <div className="loc">
            <span className="location">Heythrop Park Resort</span>
            <span className="location">Sunday 11th July</span>
          </div>
          </div>
          <div className="cplImg">
            <div className="img"></div>
          </div>
          <div className="nav-btn-container">
            <div className="nav-btn" onClick={(e)=> navToSearch(e)}>Find Your Table</div>
          </div>
        </div>
      </div>
    </Div100vh>
  )
}
export default Home;