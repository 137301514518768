import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Div100vh from 'react-div-100vh'

import { guest } from './guests';

function Search() {
  const history = useHistory();
  const [key, setKey] = useState('');
  const [selectedPerson, setPerson] = useState('');
  const [filteredGuest, setFilteredGuest] = useState([]);
  const [tableWith, setTableWith] = useState([]);
  // const handleChange = e => {
  //   setKey(e.target.value.toLowerCase())
  // }

  const filterGuest = (e) => {
    const key = e.target.value.toLowerCase();
    if(key && key.length >=3){
      setKey(e.target.value.toLowerCase());
    }else{
      setKey('');
    }    
    const allGuest = Object.assign([], guest);
    const guestReduced = allGuest.filter(g=> (key && key.length >=3 && (g.name.toLowerCase().includes(key))))
    setFilteredGuest(guestReduced);
  }

  const handleSelect = (g) => {
    setPerson(g)
  }
  const navToHome = (e) => {
    e.preventDefault();
    history.push('/')
  }

  const backToSearch = (e) => {
    e.preventDefault();
    setPerson('');
    setKey('');
    setFilteredGuest([]);
    setTableWith([]);
  }

  useEffect(()=>{
    const allGuest = Object.assign([], guest);
    const tableSharingWith = allGuest.filter(g=> {
      return (g.tableNumber === selectedPerson.tableNumber && 
        (`${g.name}` !== `${selectedPerson.name}`)
        )
      })
    setTableWith(tableSharingWith);
  }, [selectedPerson])

  return (
    <Div100vh>
    <div className="my-table">
      <div className="leaf1"></div>
      <div className="leaf2"></div>
      {!selectedPerson ? <div className="find-table">
        <form>
          <div className="tb-form">
            <label className="find-title">Thank you for joining us on our special day...</label>
            <input className="input" type="text" name="key" placeholder="Please Enter Your Name" onChange={filterGuest} />
            {/* <div className="find-btn" type="button" onClick={filterGuest}>Find</div> */}
          </div>
        </form>
        <div className="table-mates">
          {filteredGuest && filteredGuest.length > 0 && <div className="tm-title">Please Click on Your Name</div>}
          {filteredGuest && filteredGuest.length > 0 && <ul>
            {filteredGuest.map((g, index) => 
            <li key={index} onClick={() => handleSelect(g)}>{g.name}</li>)}
          </ul>}
          {key && filteredGuest.length == 0 && <div className="noGuest">
            <span className="sr">Sorry</span>
            <span>We are unable to find your name on our guest list</span>
            <span>Please try again</span>
            <span>or</span>
            <span>Please contact Wilson Olakkengil on 07956381337</span>
          </div>}
        </div>
        <div className="back-btn" onClick={(e)=> navToHome(e)}>Back to home</div>
        </div>:
        <div className="table-detail">
          <div className="w1">
            <div className="f1">Hello</div>
            <div className="my-name">{selectedPerson.name}</div>
            <div className="f1">You are on</div>
            <div className="my-name">{`Table ${selectedPerson.tableNumber}`}</div>
            <div className="f1">With</div>
          </div>
          <ul className="tabele-mates">
            {tableWith.map((g, index) => 
            <li key={index}>{`${g.name}`}</li>)}
          </ul>
          <div className="back-btn" onClick={(e) => backToSearch(e)}>Back to search</div>
        </div>}
    </div>
    </Div100vh>
  )
}
export default Search;