import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
let refreshing = false;
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// serviceWorkerRegistration.register({
//   onUpdate: registration => {
//     const waitingServiceWorker = registration.waiting

//     if (waitingServiceWorker) {
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//       waitingServiceWorker.addEventListener("statechange", event => {
//         console.log(refreshing)
//         if(refreshing){
//           return
//         }
//         if (event.target.state === "activated") {
//           refreshing = true;
//           return
//           // window.location.reload()
//         }
//       });
//     }
//   }
// });

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
