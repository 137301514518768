import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './Home';
import Search from './Search';
import ThankYou from './ThankYou';

function App() {
  return (
    <div className="weddingApp">
      <Switch>
        <Route exact path='/' component={ThankYou}></Route>
        <Route exact path='/search' component={Search}></Route>
        <Route exact path='/thankyou' component={ThankYou}></Route>
      </Switch>
    </div>
  )
}
export default App;