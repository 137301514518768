import React from 'react';
import { useHistory } from "react-router-dom";
import Div100vh from 'react-div-100vh'

function Home() {
  const history = useHistory();

  return (
    <Div100vh>
      <div className="home">
        <div className="cherryFlower"></div>
        <div className="cherryFlowerBtm"></div>
        <div className="overlay">
          <div className="welcome">
            <span className="thankyou-note">Thank you for joining us on our special day...</span>
            <div className="couples">
              <span className="ch">Ronica</span>
              <span className="ch">&</span>
              <span className="ch">Joseph</span>
            </div>
          </div>
          <div className="cplImg thnk">
            <div className="img"></div>
          </div>
        </div>
      </div>
    </Div100vh>
  )
}
export default Home;