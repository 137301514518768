import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from "./container/App";

import './App.css';

function DashApp() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}

export default DashApp;